export const SET_INSTALLATION_FILE_DATA = "SET_INSTALLATION_FILE_DATA";
export const DATA_FETCHING_INIT = "DATA_FETCHING_INIT";
export const DATA_FETCHING_DISMISS = "DATA_FETCHING_DISMISS";
export const FETCH_INSTALLATION_FILE_LIST_COMPLETE = "FETCH_INSTALLATION_FILE_LIST_COMPLETE";
export const OPEN_NEW_UPLOAD_POPUP = "OPEN_NEW_UPLOAD_POPUP";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const DATA_SUBMIT_INIT = "DATA_SUBMIT_INIT";
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const UPLOAD_INSTALLATION_FILE_COMPLETE = "UPLOAD_INSTALLATION_FILE_COMPLETE";
export const SET_FILTER_STATUS = "SET_FILTER_STATUS";
export const OPEN_ERR_FILE_DOWNLOAD_POPUP = "OPEN_ERR_FILE_DOWNLOAD_POPUP";
export const CLOSE_ERR_FILE_DOWNLOAD_POPUP = "CLOSE_ERR_FILE_DOWNLOAD_POPUP";
export const FETCH_INSTALLATION_FILE_ASSET_LIST_COMPLETE = "FETCH_INSTALLATION_FILE_ASSET_LIST_COMPLETE";
export const INSTALLATION_ASSET_FETCHING_INIT = "INSTALLATION_ASSET_FETCHING_INIT";
export const INSTALLATION_ASSET_FETCHING_COMPLETE = "INSTALLATION_ASSET_FETCHING_COMPLETE";
export const INSTALLATION_ASSET_FETCHING_DISMISS = "INSTALLATION_ASSET_FETCHING_DISMISS";
export const OPEN_NEW_PROCESS_FAILED = "OPEN_NEW_PROCESS_FAILED";
export const CLOSE_POP_UP ="CLOSE_POP_UP";
export const OPEN_POP_UP_VIEW_QUERY="OPEN_POP_UP_VIEW_QUERY";
export const SET_OPEN_VIEW_QUERY="SET_OPEN_VIEW_QUERY";
export const CLOSE_SUPPORTDOCUMENT_PREVIEW="CLOSE_SUPPORTDOCUMENT_PREVIEW";
export const EDIT_QUERY_PREVIEW="EDIT_QUERY_PREVIEW";
export const UPLOAD_SUPPORTDOCUMENT_PREVIEW="UPLOAD_SUPPORTDOCUMENT_PREVIEW";
export const COMMENTS_PREVIEW="COMMENTS_PREVIEW"
export const COMMENTS_DISMISS="COMMENTS_DISMISS"
export const ADD_NEW_COMMENTS="ADD_NEW_COMMENTS"
export const ACCEPT_REJECT_PENDING_REC_FETCHING_INIT = "ACCEPT_REJECT_PENDING_REC_FETCHING_INIT";
export const ACCEPT_REJECT_PENDING_REC_FETCHING_COMPLETE = "ACCEPT_REJECT_PENDING_REC_FETCHING_COMPLETE";
export const ACCEPT_REJECT_PENDING_REC_FETCHING_DISMISS = "ACCEPT_REJECT_PENDING_REC_FETCHING_DISMISS";
export const ACCEPT_INSTALL_ACCEPT_REJECT_INIT="ACCEPT_INSTALL_ACCEPT_REJECT_INIT";
export const ACCEPT_INSTALL_ACCEPT_REJECT_COMPLETE = "ACCEPT_INSTALL_ACCEPT_REJECT_COMPLETE";
export const ACCEPT_INSTALL_ACCEPT_REJECT_DISMISS = "ACCEPT_INSTALL_ACCEPT_REJECT_DISMISS";

const initialState = {
    comment_data:"",
    outstand_installationfile_list:[],
    installationfile_list: [], // all installation files 
    total_installationfiles: 0,//total count
    installationfileasset_list: [], // all installation files 
    total_installationfileasset: 0,//total count
    data_fetching: false, //indicaction for fetch in progress
    installationfileassetlist: false, //indicaction for fetch in progress
    installationfileassetlist_refetch: false, //indicaction for fetch in progress
    installationfilelist_refetch: false,//indication to refetch current installation file list array
    details_view: 0, // 0 -> show index, 1-> view installation file, 2-> view error file, 
    insta_upload_popup: null,//to display file upload popup flag
    search: "",//search keyword on installation file name listing
    page: 1,//page number on file list
    perpage: 10,//perpage limit on file list
    add_btn_disable: false,
    installation_file_title: "",
    installation_file_two: "",
    installation_file_three: "",
    filter_status_installation: "",
    installation_file_date: "",
    installationfile_details: {}, //obj to store one installation row data in list
    download_popup: false,
    download_btn_disable: false,
    show_installation_list:true,
    installation_download_data:{},
    ref_search : "",
    record_page : 1,
    record_perpage :10,
    energy_suppliers: [],
    installers: [],
    selected_installer_id: "",
    selected_energy_supl_id: "",
    installation_files:[],
    sfe_files:[],
    dcc_files:[],
    ecoes_files:[],
    xoserve_files:[],
    other_files:[],
    dnd_count:1, // count for multiple dragand drop
    open_popup:false,
    installation_view:0,
    setSFE:false,
    setOutstand_flag:false,
    upload_flag:false,
    approve_popup:false,
    rejectpopup:false,
    installation_file_id:"",
    status:"",
    error_data:[],
    open_view_query_popup: false,
    view_data_fetching:false,
    install_approve_btn_disable:false,
    download_support_flag :false,
    download_csv :"",
    refetch_list: false,
    total_supportdoc_count : 0,
    supportdoc_perpage : 10,
    supportdoc_page:1,
    support_file_type_desc:"",
    support_file_type:"",
    match:"",
    export_support_flag_value:1,
    support_file_query : {},
    supportDocFlag : false,
    support_doc_modal:false,
    total_unverified_count:0,
    unverified_page:1,
    unverified_perpage:10, 
    support_unverifiedfile_query : {},
    support_unverifiedfile_type_desc:"",
    unverified_modal:false,
    revoke_edit_action:[],
    supportdoc:{},
    editsupportdoc:null,
    selectedData:[],

    ef_install_location: "" , ef_postcode: "" , ef_mpan: "" , ef_mprn: "" , ef_document_type: "" , ef_contact_id: "" , ef_job_date: "" ,
    eq_install_location:"",eq_postcode:"",eq_mpan:"",eq_mprn:"",eq_new_elec_msn:"",eq_new_gas_msn:"",eq_ihdserial:"",eq_regulator_serial:"",eq_old_elec_msn:"",eq_old_gas_msn:"",
    is_record_exist:"", date_sent:"", bau_flag:"" ,energy_supplier_value:"" ,map_value:"", file_uploaded_date:"",address:"",postcode:"",mpan:"",elec_msn:"",elec_msn_installation_date:"",ihd_serial:"",ihd_serial_installation_date:"",mprn:"",gas_msn:"",gas_msn_installation_date:"",
    filelist_refetch: false,
    delete_file_btn_disable: false,
    edit_file_btn_disable : false,

    core_file_uploaded_date:"",core_address:"",core_postcode:"",core_mpan:"",core_elec_msn:"",core_elec_msn_installation_date:"",core_ihd_serial:"",core_ihd_serial_installation_date:"",core_mprn:"",core_gas_msn:"",core_gas_msn_installation_date:"",
    mpan_mprn_search : "",
    edit_support_doc_count:0,
    edit_support_doc : {},
    toUpload_installation_file_id:"",
    toUpload_file_uploaded_date:"", 
    toUpload_address:"",
    toUpload_postcode:"", 
    toUpload_mpan:"", 
    toUpload_elec_msn:"", 
    toUpload_elec_msn_installation_date:"", 
    toUpload_ihd_serial:"", 
    toUpload_ihd_serial_installation_date:"", 
    toUpload_mprn:"", 
    toUpload_gas_msn:"", 
    toUpload_gas_msn_installation_date  :"",
    toUpload_date_sent:"",
    toUpload_bau_flag:"",toUpload_map_value:"",
    toUpload_is_record_exist:"",
    toUpload_energy_supplier_value:"",
    address_validate:"",
    bau_flag_validate:"",
    date_sent_validate:"",
    elec_msn_installation_date_validate:"",
    energy_supplier_value_validate:"",
    gas_msn_installation_date_validate:"",
    gas_msn_validate:"",
    ihd_serial_installation_date_validate:"",
    ihd_serial_validate:"",
    map_validate:"",
    mpan_validate:"",
    mprn_validate:"",
    postcode_validate:"",
    elec_msn_validate:"",
    is_loader_required:0,
    loading_flag:false,

    ma_installation_file_id:"",ma_is_record_exist:"",ma_date_sent:"", ma_bau_flag:"", ma_energy_supplier_value:"", ma_map_value:"", ma_file_uploaded_date:"", ma_address:"", ma_postcode:"", ma_mpan:"", ma_elec_msn:"", ma_elec_msn_installation_date:"", ma_ihd_serial:"", ma_ihd_serial_installation_date:"", ma_mprn:"", ma_gas_msn:"", ma_gas_msn_installation_date:"",
    // intial value for sfe
    sfe_installation_file_id:"",sfe_is_record_exist:"", sfe_date_sent:"",sfe_bau_flag:"",sfe_energy_supplier_value:"",sfe_map_value:"", sfe_file_uploaded_date:"", sfe_address:"", sfe_postcode:"", sfe_mpan:"", sfe_elec_msn:"", sfe_elec_msn_installation_date:"", sfe_ihd_serial:"", sfe_ihd_serial_installation_date:"", sfe_mprn:"", sfe_gas_msn:"", sfe_gas_msn_installation_date:"",
    dcc_date_sent:"", dcc_bau_flag:"", dcc_energy_supplier_value:"", dcc_map_value:"",dcc_installation_file_id:"",dcc_is_record_exist:"", dcc_file_uploaded_date:"", dcc_address:"", dcc_postcode:"", dcc_mpan:"", dcc_elec_msn:"", dcc_elec_msn_installation_date:"", dcc_ihd_serial:"", dcc_ihd_serial_installation_date:"", dcc_mprn:"", dcc_gas_msn:"", dcc_gas_msn_installation_date:"",
    xoserve_date_sent:"", xoserve_bau_flag:"", xoserve_energy_supplier_value:"", xoserve_map_value:"",ecoes_date_sent:"", ecoes_bau_flag:"", ecoes_energy_supplier_value:"", ecoes_map_value:"", ecoes_is_record_exist:"", ecoes_file_uploaded_date:"", ecoes_address:"", ecoes_postcode:"", ecoes_mpan:"", ecoes_elec_msn:"", ecoes_elec_msn_installation_date:"", ecoes_ihd_serial:"", ecoes_ihd_serial_installation_date:"", ecoes_mprn:"", ecoes_gas_msn:"", ecoes_gas_msn_installation_date:"",xoserve_installation_file_id:"",ecoes_installation_file_id:"",xoserve_is_record_exist:"", xoserve_file_uploaded_date:"", xoserve_address:"", xoserve_postcode:"", xoserve_mpan:"", xoserve_elec_msn:"", xoserve_elec_msn_installation_date:"", xoserve_ihd_serial:"", xoserve_ihd_serial_installation_date:"", xoserve_mprn:"", xoserve_gas_msn:"", xoserve_gas_msn_installation_date:"",
    other_date_sent:"", other_bau_flag:"", other_energy_supplier_value:"", other_map_value:"",other_installation_file_id:"",other_is_record_exist:"", other_file_uploaded_date:"", other_address:"", other_postcode:"", other_mpan:"", other_elec_msn:"", other_elec_msn_installation_date:"", other_ihd_serial:"", other_ihd_serial_installation_date:"", other_mprn:"", other_gas_msn:"", other_gas_msn_installation_date:"",
    comment_popup:false,
    comment_text:"",
    refetchqueryinfile_list: true,
    force_resolve_comment_flag:"",
    match_flag:false,
    unverified_flag:false,
    dcc_compare_flag:false,
    smso_compare_flag:false,
    view_flag:0,
    fileCount:"",
    conflicts:0,
    conflict_list_refetch:false,
    conflict_page:1,
    conflicts_perpage: 10,
    conflict_search:"",
    support_file_types:[],
    conflict_details:[],
    total_conflictsdata_count:0,
    conflict_loading_flag:false,
    support_type:[],
    is_record_exists:0,

    Smdcc_perpage:10,
    Smdcc_page:1,
    total_SmDcc_list:0,
    Smdcc_file_list:[],
    SmDcc_refetch:false,
    file_type:0,
    support_ids:[],
    cmt_add_btn:false,
    pendingApprovalRejectPage:1, //single record approvals
    pendingApprovalRejectPerPage:10,
    pendingApprovalRejectIsFetching:false,
    pendingApprovalRejectViewData:[],
    pendingApprovalRejectTotalData:0,
    pendingApprovalRejectSearch:"",
    pendingApprovalRejectRefetch:false,
    recordToSet:0,
    export_support_flag:1,
    support_download_flag:false,
    install_single_approve_btn_disable:false,
    status_count:{}
};

export default (state = initialState, action = {}) => {


    switch (action.type) {
        case SET_INSTALLATION_FILE_DATA:
            return { ...state, ...action.payload };
        case OPEN_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case CLOSE_ERR_FILE_DOWNLOAD_POPUP:
            return { ...state, ...action.payload };
        case SET_FILTER_STATUS:
            return { ...state, ...action.payload };
        case CLOSE_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case OPEN_NEW_UPLOAD_POPUP:
            return { ...state, ...action.payload };
        case FETCH_INSTALLATION_FILE_LIST_COMPLETE:
            return { ...state, ...action.payload };
        case OPEN_NEW_PROCESS_FAILED:
            return { ...state,...action.payload };
        case CLOSE_POP_UP:
            return { ...state,...action.payload };
        case INSTALLATION_ASSET_FETCHING_INIT:
            return { ...state, ...action.payload,data_fetching: false,installationfileassetlist:true,installationfileassetlist_refetch: false,show_installation_list:false };
        case INSTALLATION_ASSET_FETCHING_COMPLETE:
            return { ...state, ...action.payload, data_fetching: false,installationfileassetlist:false,installationfileassetlist_refetch: false,show_installation_list:false};
        case INSTALLATION_ASSET_FETCHING_DISMISS:
            return {...state, ...action.payload, data_fetching: false,installationfileassetlist:false,installationfileassetlist_refetch: false,show_installation_list:false};
        case UPLOAD_INSTALLATION_FILE_COMPLETE:
            return { ...state, ...action.payload };
        case DATA_FETCHING_INIT:
            return { ...state, ...action.payload, data_fetching: true, };
        case DATA_FETCHING_DISMISS:
            return { ...state, ...action.payload, data_fetching: false };
        case DATA_SUBMIT_INIT:
            return { ...state, ...action.payload,add_btn_disable:true, upload_flag:true};
        case DATA_SUBMIT_DISMISS:
            return { ...state, ...action.payload, add_btn_disable: false };
        case OPEN_POP_UP_VIEW_QUERY:
            return { ...state, ...action.payload }
        case SET_OPEN_VIEW_QUERY:
            return { ...state, ...action.payload };
        case CLOSE_SUPPORTDOCUMENT_PREVIEW:
            return { ...state, ...action.payload }
        case EDIT_QUERY_PREVIEW:
            return { ...state, ...action.payload }
        case UPLOAD_SUPPORTDOCUMENT_PREVIEW:
            return { ...state, ...action.payload }
        case COMMENTS_PREVIEW: 
            return { ...state, ...action.payload } 
        case COMMENTS_DISMISS: 
            return { ...state,comment_text:"", comment_popup:false,...action.payload}
        case ADD_NEW_COMMENTS: {
                return { ...state, refetchqueryinfile_list: true, search: "", cmt_add_btn: false }
            }
        case ACCEPT_REJECT_PENDING_REC_FETCHING_INIT:
            return { ...state, ...action.payload, pendingApprovalRejectIsFetching: true};
        case ACCEPT_REJECT_PENDING_REC_FETCHING_COMPLETE:
            return { ...state, ...action.payload, pendingApprovalRejectIsFetching: false,pendingApprovalRejectRefetch: false};
        case ACCEPT_REJECT_PENDING_REC_FETCHING_DISMISS:
            return {...state, ...action.payload, pendingApprovalRejectIsFetching: false,pendingApprovalRejectRefetch: false};
        case ACCEPT_INSTALL_ACCEPT_REJECT_INIT: 
            return { ...state, ...action.payload,install_single_approve_btn_disable:true}
        case ACCEPT_INSTALL_ACCEPT_REJECT_COMPLETE:
            return { ...state, ...action.payload}
        case ACCEPT_INSTALL_ACCEPT_REJECT_DISMISS:
            return { ...state, ...action.payload, install_single_approve_btn_disable:false}
        default:
            return state;
    }
};
